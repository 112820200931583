import * as React from 'react'
import { Link } from 'gatsby'

interface HeroAction {
	readonly label: string
	readonly url: string
}

interface HeroHeaderProps {
	readonly title: string
	readonly subtitle: string
	readonly description: string
	readonly action?: HeroAction
}

const HeroHeader = ({
	title,
	subtitle,
	description,
	action
}: HeroHeaderProps) => (
	<header className='w-full flex lg:grid lg:grid-cols-1 lg:grid-rows-2 sm:h-screen'>
		<div className='w-full my-auto px-12 pb-24 pt-36 lg:p-24 lg:row-start-2'>
			<h1
				id='hero-title'
				className='font-extrabold text-gray-200 mb-2 text-2xl sm:text-4xl lg:text-6xl'
			>
				{title}
			</h1>
			<h2
				id='hero-subtitle'
				className='font-bold text-gray-300 mb-4 sm:text-2xl lg:text-3xl'
			>
				{subtitle}
			</h2>
			<p
				id='hero-description'
				className='text-gray-400 text-sm sm:text-base lg:text-xl'
			>
				{description}
			</p>
			{action ? (
				<Link
					id='btn-betnow'
					className='btn btn-primary mt-12 shadow-lg hover:-translate-y-0.5 transform transition'
					to={action.url}
				>
					{action.label}
				</Link>
			) : (
				<div />
			)}
		</div>
	</header>
)

export default HeroHeader
