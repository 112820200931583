import * as React from 'react'

const Footer = () => (
	<footer className='bg-yellow-400 p-10'>
		<p className='text-gray-50 text-center'>
			© 2021 Luckydaybet99. All Rights Reserved.
		</p>
	</footer>
)

export default Footer
