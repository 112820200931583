export interface NavigatableItem {
	readonly id: string
	readonly label: string
	readonly url: string
}

export function constructNavigatableItem(
	prototype: NavigatableItem
): NavigatableItem {
	return { ...prototype }
}
