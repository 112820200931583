import { graphql, useStaticQuery } from 'gatsby'
import { pipe } from 'fp-ts/function'

export interface SiteData {
	readonly url: string
	readonly title: string
	readonly subtitle: string
	readonly description: string
}

const constructSiteData = (x: SiteData) => {
	return { ...x } as SiteData
}

export const getSiteData = () =>
	pipe(
		useStaticQuery<QueryResult>(query),
		x => x.site.siteMetadata,
		x =>
			constructSiteData({
				url: x.siteUrl,
				title: x.title,
				subtitle: x.subtitle,
				description: x.description
			})
	)

type QueryResult = {
	site: {
		siteMetadata: {
			siteUrl: string
			title: string
			subtitle: string
			description: string
		}
	}
}

const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
				title
				subtitle
				description
			}
		}
	}
`
