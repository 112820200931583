import { graphql, useStaticQuery } from 'gatsby'
import { pipe } from 'fp-ts/function'
import * as Array from 'fp-ts/Array'
import * as N from 'fp-ts/number'
import { contramap } from 'fp-ts/Ord'

export interface PageSheet {
	readonly id: string
	readonly slug: string
	readonly img: string
	readonly label: string
	readonly description: string
	readonly isFeatured: boolean
}

const byRank = pipe(
	N.Ord,
	contramap((m: Mdx) => +m.frontmatter.rank)
)
export const getFeaturedSheets = () =>
	pipe(
		useStaticQuery<FeaturedSheetsQueryResult>(featuredSheetsQuery),
		query => query.allFile.nodes,
		Array.map(node => node.childMdx),
		Array.sortBy([byRank]),
		Array.map(mdxToPageSheet)
	)

function mdxToPageSheet(mdx: Mdx): PageSheet {
	return {
		id: mdx.id,
		slug: mdx.slug,
		img: mdx.frontmatter.img,
		label: mdx.frontmatter.label,
		description: mdx.frontmatter.description,
		isFeatured: mdx.frontmatter.featured.toLowerCase() === 'true'
	}
}

interface Mdx {
	readonly frontmatter: {
		img: string
		label: string
		description: string
		featured: string
		rank: string
	}
	readonly id: string
	readonly slug: string
}

type FeaturedSheetsQueryResult = {
	allFile: {
		nodes: {
			childMdx: Mdx
		}[]
	}
}

const featuredSheetsQuery = graphql`
	query {
		allFile(
			filter: {
				sourceInstanceName: { eq: "sheets" }
				childMdx: { frontmatter: { featured: { eq: "true" } } }
			}
		) {
			nodes {
				childMdx {
					frontmatter {
						img
						label
						description
						featured
						rank
					}
					id
					slug
				}
			}
		}
	}
`
