import * as React from 'react'
import Nav from './nav'
import '../styles/layout.css'
import { NavigatableItem } from '../common/navigatable'
import { FaWhatsapp } from 'react-icons/all'

interface LayoutProps {
	readonly img: string
	readonly siteUrl: string
	readonly title: string
	readonly subtitle: string
	readonly children: React.ReactNode
	readonly navigationItems: NavigatableItem[]
}

const Layout = ({
	img,
	siteUrl,
	title,
	subtitle,
	children,
	navigationItems
}: LayoutProps) => {
	const [opacity, setOpacity] = React.useState(0)
	React.useEffect(() => {
		const handleScroll = (_: Event) =>
			setOpacity(Math.min(360, window.scrollY))
		document.addEventListener('scroll', handleScroll)
		return () => document.removeEventListener('scroll', handleScroll)
	}, [])

	const bgImageStyles = {
		backgroundImage: `url("${img}")`
	}

	return (
		<div className='hero-bg' style={bgImageStyles}>
			<div className='relative h-full bg-opacity-50 bg-black'>
				<Nav
					siteUrl={siteUrl}
					title={title}
					subtitle={subtitle}
					opacity={opacity}
					items={navigationItems}
				/>
			</div>
			<main className='absolute top-0 w-full min-h-full'>{children}</main>
			<a
				id='btn-whatsapp'
				className='btn fixed z-40 bg-green-500 hover:bg-green-400 focus:ring-green-400 focus:ring-opacity-50 active:bg-green-600 text-white shadow right-4 bottom-4 sm:right-12 sm:bottom-12'
				href='https://wa.me/6598662857?text='
			>
				<FaWhatsapp className='inline sm:mr-2' size={24} />
				<span className='tracking-widest hidden sm:inline'>
					WhatsApp Me
				</span>
			</a>
		</div>
	)
}

export default Layout
