import * as React from 'react'
import { NavigatableItem } from '../common/navigatable'
import { Link } from 'gatsby'

interface NavProps {
	readonly siteUrl: string
	readonly title: string
	readonly subtitle: string
	readonly opacity: number
	readonly items: NavigatableItem[]
}

const Nav = ({ siteUrl, title, subtitle, opacity, items }: NavProps) => {
	const opacityStyles = {
		backgroundColor: `rgba(31, 41, 55, ${opacity})`,
		boxShadow: opacity >= 1 ? '0px 4px 8px rgba(0, 0, 0, 0.5)' : 'none'
	}

	return (
		<nav
			className='flex items-center px-8 py-4 transition w-full fixed z-10 top-0'
			style={opacityStyles}
		>
			<a href={siteUrl}>
				<div className='flex flex-col items-baseline space-y-2'>
					<h1
						id='nav-title'
						className='font-semibold text-gray-50 text-4xl tracking-widest'
					>
						{title}
					</h1>
					<h2 id='nav-subtitle' className='text-gray-300'>
						{subtitle}
					</h2>
				</div>
			</a>
			<div className='flex-grow' />
			{/*For Large Screen*/}
			<ul
				id='nav-items'
				className='items-center space-x-4 hidden lg:flex'
			>
				{items.map(it => (
					<li key={it.id}>
						<Link className='font-medium text-white' to={it.url}>
							{it.label}
						</Link>
					</li>
				))}
			</ul>
			{/*For Small Screen*/}
			<ul className='hidden items-center space-x-4 sm:flex lg:hidden'>
				<li>
					<Link className='font-medium text-white' to={items[0].url}>
						{items[0].label}
					</Link>
				</li>
			</ul>
		</nav>
	)
}

export default Nav
